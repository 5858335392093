/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { menuNavigationEvent } from 'src/lib/analytics';

const links = {
  legal: {
    href: 'https://www.andrewsmcmeel.com/terms-of-service',
    title: 'Terms of Service',
  },
  privacyPolicy: {
    href: 'https://www.andrewsmcmeel.com/privacy-policy/',
    title: 'Privacy Policy',
  },
};

const Auxiliary = ({ navigationType }) => (
  <>
    <Link href="/about">
      <a
        onClick={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'about')
            : undefined
        }
        onKeyUp={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'about')
            : undefined
        }
      >
        About
      </a>
    </Link>
    <Link href="/contact">
      <a
        onClick={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'contact')
            : undefined
        }
        onKeyUp={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'contact')
            : undefined
        }
      >
        Contact
      </a>
    </Link>
    <Link href="/submissions">
      <a
        onClick={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'submissions')
            : undefined
        }
        onKeyUp={
          navigationType
            ? () => menuNavigationEvent(navigationType, 'submissions')
            : undefined
        }
      >
        Submissions
      </a>
    </Link>
    {Object.keys(links).map((destination) => (
      <a
        href={`${links[destination].href}`}
        key={links[destination].href}
        rel="noopener noreferrer"
        target="_blank"
        onClick={
          navigationType
            ? () =>
                menuNavigationEvent(navigationType, links[destination].title)
            : undefined
        }
        onKeyUp={
          navigationType
            ? () =>
                menuNavigationEvent(navigationType, links[destination].title)
            : undefined
        }
      >
        {links[destination].title}
      </a>
    ))}
  </>
);

Auxiliary.propTypes = {
  navigationType: PropTypes.string,
};

Auxiliary.defaultProps = {
  navigationType: '',
};

export default Auxiliary;
