/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';

import { menuNavigationEvent } from 'src/lib/analytics';

import styles from './Primary.module.scss';

const categoryLinks = {
  life: 'Life',
  parenting: 'Parenting',
  home: 'Home',
  pets: 'Pets',
  health: 'Health',
  astrology: 'Astrology',
  oddities: 'Oddities',
};

const Primary = ({
  addCategoryModifiers,
  navigationType,
  primaryLinkStyles,
  variant,
}) => {
  const router = useRouter();

  const defaultStyles = (destination) =>
    classNames([
      primaryLinkStyles,
      addCategoryModifiers && [
        styles[`primary--${destination}`],
        router.asPath === `/${destination}` &&
          styles[`primary--${destination}-active`],
      ],
    ]);

  return (
    <>
      {Object.keys(categoryLinks).map((destination) => (
        <Link as={`/${destination}`} href="/[category]" key={destination}>
          <a
            className={defaultStyles(destination)}
            data-testid={`primary-${navigationType}-navigation-${destination}`}
            onClick={
              navigationType
                ? () => menuNavigationEvent(navigationType, destination)
                : undefined
            }
            onKeyUp={
              navigationType
                ? () => menuNavigationEvent(navigationType, destination)
                : undefined
            }
          >
            {categoryLinks[destination]}
          </a>
        </Link>
      ))}
      <Link href="/a-to-z">
        <a
          className={classNames(primaryLinkStyles, {
            [styles.primary_active]:
              router.asPath === '/a-to-z' && variant === 'header',
          })}
          data-testid={`primary-${navigationType}-navigation-az`}
          onClick={
            navigationType
              ? () => menuNavigationEvent(navigationType, 'a-to-z')
              : undefined
          }
          onKeyUp={
            navigationType
              ? () => menuNavigationEvent(navigationType, 'a-to-z')
              : undefined
          }
        >
          A-Z
        </a>
      </Link>
    </>
  );
};

Primary.propTypes = {
  addCategoryModifiers: PropTypes.bool,
  navigationType: PropTypes.string,
  primaryLinkStyles: PropTypes.string,
  variant: PropTypes.string,
};

Primary.defaultProps = {
  addCategoryModifiers: false,
  navigationType: null,
  primaryLinkStyles: null,
  variant: null,
};

export default Primary;
