import React, { useEffect, useState, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useMediaQuery, useWindowScroll } from 'beautiful-react-hooks';

import breakpoints from 'src/lib/configs/breakpoints';
import getScssInt from 'src/lib/utilities/scssUtils';

import { BreakpointBetween, BreakpointUpLg } from '../global/Breakpoints';
import Mobile from './Mobile';
import Desktop from './Desktop';

import styles from './Header.module.scss';

const TopAdvertisingWrapper = dynamic(
  () => import('src/components/ads/wrappers/TopAdvertisingWrapper'),
  {
    ssr: false,
  }
);

const Header = () => {
  // Ad Positioning
  const router = useRouter();
  const [scrollY, setScrollY] = useState(window.scrollY);

  const [adIsVisible, setAdIsVisible] = useState(true);
  const [adIsSticky, setAdIsSticky] = useState(true);
  const [adHasAnimated, setAdHasAnimated] = useState(false);

  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm.max}px)`);
  const adHeight = isMobile
    ? getScssInt(styles.var_adHeightMobile)
    : getScssInt(styles.var_adHeightDesktop);

  const startStickyAdTimer = useCallback(() => {
    setTimeout(() => {
      setAdIsSticky(false);
      setTimeout(() => {
        setAdHasAnimated(true);
      }, styles.var_animationTimer);
    }, 5000);
  }, [setAdIsSticky, setAdHasAnimated]);

  useWindowScroll(() => {
    setScrollY(window.scrollY);
    setAdIsVisible(window.scrollY < adHeight);
  });

  useEffect(() => {
    // Start ad timer on initial render
    startStickyAdTimer();
  }, [startStickyAdTimer]);

  useEffect(() => {
    // eslint-disable-next-line no-shadow-restricted-names
    const handleRouteChangeComplete = (undefined, { shallow }) => {
      // !shallow will exclude 'read more' route changes
      // in the Article component
      if (!shallow) {
        // Make the ad sticky again and start the timer to release it
        setAdIsVisible(true);
        setAdIsSticky(true);
        setAdHasAnimated(false);
        startStickyAdTimer();
      }
    };
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router, startStickyAdTimer]);

  return (
    <>
      <div
        className={classNames(styles.adContainer, {
          [styles.adContainer_animatePosition]:
            !adIsSticky && !adHasAnimated && !adIsVisible,
        })}
        style={
          adHasAnimated
            ? { top: scrollY < adHeight ? -scrollY : -adHeight }
            : {}
        }
      >
        <TopAdvertisingWrapper padded />
      </div>
      <header
        className={classNames(styles.headerContainer, {
          [styles.headerContainer_animatePosition]:
            !adIsSticky && !adHasAnimated && !adIsVisible,
        })}
        style={
          adHasAnimated
            ? { top: scrollY > adHeight ? 0 : adHeight - scrollY }
            : {}
        }
      >
        <div className={styles.header}>
          <BreakpointBetween maxBreakpoint="md" minBreakpoint="xs">
            <Mobile />
          </BreakpointBetween>
          <BreakpointUpLg>
            <Desktop />
          </BreakpointUpLg>
        </div>
      </header>
    </>
  );
};

export default Header;
