import React, { useEffect } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import TagManager from 'react-gtm-module';
import { DefaultSeo } from 'next-seo';
import PropTypes from 'prop-types';

import initSentry from 'src/lib/initSentry';
import { virtualPageviewEvent } from 'src/lib/analytics';

// Components
// import { AdProvider } from 'src/components/ads/AdContext';
import FullAdvertisingWrapper from 'src/components/ads/wrappers/FullAdvertisingWrapper';
import Footer from 'src/components/footer/Footer';

import SEO from '../next-seo.config';

// Styles
import '../scss/index.scss';

// Sentry
initSentry();

const Header = dynamic(() => import('../components/header/Header'), {
  ssr: false,
});

function UExpress({ Component, pageProps, err }) {
  const router = useRouter();

  useEffect(() => {
    TagManager.initialize({
      gtmId: 'GTM-N3LDVHN',
      dataLayer: {
        event: 'dataLayer-initialized',
      },
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      virtualPageviewEvent(router.asPath, url);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.asPath, router.events]);

  const Template = Component.template;
  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <DefaultSeo {...SEO} />
      <Head>
        <link
          crossOrigin="true"
          href="https://cdn.confiant-integrations.net"
          rel="preconnect"
        />
        <link
          href="/images/favicon/apple-touch-icon.png"
          key="apple-touch-icon"
          rel="apple-touch-icon"
          sizes="180x180"
        />
        <link
          href="/images/favicon/favicon-32x32.png"
          key="favicon-32"
          rel="icon"
          sizes="32x32"
          type="image/png"
        />
        <link
          href="/images/favicon/favicon-16x16.png"
          key="favicon-16"
          rel="icon"
          sizes="16x16"
          type="image/png"
        />
        <link
          href="/images/favicon/site.webmanifest"
          key="webmanifest"
          rel="manifest"
        />
        <link
          as="script"
          href="https://www.googletagservices.com/tag/js/gpt.js"
          rel="preload"
        />
        <script
          async
          src="https://dn0qt3r0xannq.cloudfront.net/amu-Yxa71KhWOl/uexpress-longform/prebid-load.js"
        />
        <script src="https://utilities.amuniversal.com/unsupportedbrowsers/index.js" />
      </Head>
      <div className="ue-app-container">
        <Header />
        <main>
          {/* eslint-disable react/jsx-props-no-spreading */}
          {Template ? (
            <Template>
              <Component {...pageProps} err={err} />
            </Template>
          ) : (
            <Component {...pageProps} err={err} />
          )}
          <FullAdvertisingWrapper />
        </main>
        <Footer />
      </div>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
UExpress.propTypes = {
  Component: PropTypes.func.isRequired,
  err: PropTypes.any,
  pageProps: PropTypes.object.isRequired,
};

UExpress.defaultProps = {
  err: undefined,
};

export default UExpress;
