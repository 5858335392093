import React from 'react';

import Logo from 'src/components/global/logos/UExpress';
import PrimaryLinks from 'src/components/global/links/Primary';
import AuxiliaryNavigation from 'src/components/navigation/auxiliary/AuxiliaryNavigation';
import Copyright from 'src/components/global/Copyright';

import styles from './Footer.module.scss';

// Primary styles
const logoStyles = `nav-link ${styles.footer__navItem} ${styles.footer__navItem_logo}`;
const primaryLinkStyles = `nav-link ${styles.footer__navItem} ${styles.footer__navItem_link}`;

// Auxiliary styles
const containerStyles = `${styles.footer__auxiliaryContainer}`;
const linkStyles = `nav-link ${styles.footer__auxiliaryItem}`;
const amuStyles = `${styles.footer__copyright} ${styles.footer__copyright_link}`;

const Footer = () => (
  <footer className={styles.footer} data-print="none">
    <div className={styles.footer__container}>
      <div className={styles.footer__wrapper}>
        <div className={styles.footer__content}>
          <nav aria-label="primary" className={styles.footer__nav}>
            <Logo logoStyles={logoStyles} navigationType="footer" />
            <PrimaryLinks
              navigationType="footer"
              primaryLinkStyles={primaryLinkStyles}
            />
          </nav>
          <div className={styles.footer__auxiliary}>
            <AuxiliaryNavigation
              containerStyles={containerStyles}
              displayContext="footer"
              linkStyles={linkStyles}
              navigationType="footer"
            />
            <Copyright
              amuStyles={amuStyles}
              copyrightStyles={styles.footer__copyright}
              navigationType="footer"
            />
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
