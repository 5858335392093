import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Sourcepoint = ({ linkStyles }) => (
  <>
    <div
      className={classNames(linkStyles, 'sp-message')}
      id="gdpr_link"
      role="button"
      style={{ display: 'none' }}
      tabIndex={0}
    >
      GDPR Privacy Settings
    </div>
    <div
      className={classNames(linkStyles, 'sp-message')}
      id="ccpa_link"
      role="button"
      style={{ display: 'none' }}
      tabIndex={0}
    >
      Do Not Sell or Share my Personal Information
    </div>
    <div
      className={classNames(linkStyles, 'sp-message')}
      id="compliance_link"
      role="button"
      style={{ display: 'none' }}
      tabIndex={0}
    >
      Do Not Sell or Share my Personal Information
    </div>
  </>
);

Sourcepoint.propTypes = {
  linkStyles: PropTypes.string.isRequired,
};

export default Sourcepoint;
