import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Control.module.scss';

const Control = ({
  onClick,
  buttonStyles,
  ariaLabel,
  ariaExpanded,
  ariaControls,
  children,
}) => (
  <button
    aria-controls={ariaControls}
    aria-expanded={ariaExpanded}
    aria-label={ariaLabel}
    className={classNames(styles.button, buttonStyles)}
    type="button"
    onClick={onClick}
  >
    {children}
  </button>
);

Control.propTypes = {
  ariaControls: PropTypes.string.isRequired,
  ariaExpanded: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  buttonStyles: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

Control.defaultProps = {
  buttonStyles: '',
};

export default Control;
